'use client'

import { fetchSuburb } from '@homeSearch/modules/fetch'
import { suburbsToIds } from '@plugin/searchRoute/utils'
import { assign, isFunction, size } from 'lodash-es'
import { Suspense, useCallback, useMemo } from 'react'
import useHomeSearchContext from '../../context'
import HomeSearchInput from '../../input'
import Location from '../../location'
import HomeSearchPopup from '../../popup'
import { AGENT_TYPE_ALIAS } from '../config/content'
import useHomeSearchFilterContext from '../context'
import FilterList from './list'
import SearchType from './searchType'
import { HOUSE_TYPE_ID } from '@config'

/**
 * 首页搜索filter主体
 */
export default function HomeSearchFilterContent({
  fetcher,
  onConfirm,
  placeholder,
  mobilePlaceholder,
  showSearchType = true,
  useSuburb = true
}) {

  const { filterQuery, description, searchType } = useHomeSearchFilterContext()
  const { suburbs, surrounding, isSearchSuburb } = useHomeSearchContext()
  const params = useMemo(() => {
    if (!searchType || (!searchType.typeId && !searchType.params)) return null

    return assign(
      { typeId: searchType.typeId },
      searchType.params,
      suburbsToIds(suburbs).idStr(),
      filterQuery ? { filter: filterQuery } : undefined,
      surrounding ? { surrounding: 1 } : undefined,
      description ? { description } : undefined
    )
  }, [searchType, suburbs, filterQuery, surrounding, description])
  // 是否显示中介公司
  const showAgency = useMemo(() => searchType && searchType.alias === AGENT_TYPE_ALIAS.realEstate, [searchType])
  // 是否显示suburb
  const showSuburb = useMemo(() => searchType && searchType.typeId !== HOUSE_TYPE_ID.village, [searchType])

  const { confirmHandle } = useOnConfirm(onConfirm)

  const fetcherHandle = useCallback((keyword) => {
    if (isSearchSuburb) return fetchSuburb(keyword)
    if (!isFunction(fetcher)) return
    return fetcher(keyword, searchType.typeId)
  }, [searchType, fetcher, isSearchSuburb])
  return (
    <>
      {/* search input */}
      <Suspense>
        <HomeSearchInput onSearch={confirmHandle} placeholder={placeholder} mobilePlaceholder={mobilePlaceholder}>
          <HomeSearchPopup fetcher={fetcherHandle} showAgency={showAgency} showSuggested={!isSearchSuburb} showSuburb={showSuburb}></HomeSearchPopup>
        </HomeSearchInput>
      </Suspense>
      {/* select location from list */}
      <Location useRegion={!isSearchSuburb} useDistrict={!isSearchSuburb} useSuburb={useSuburb}></Location>
      {/* 搜索类型 */}
      {showSearchType && <SearchType className='mt-20'></SearchType>}
      {/* filter数据列表 */}
      <FilterList params={params}></FilterList>
    </>
  )
}

// 清除数据按钮
export function ClearButton() {

  const { clearSelect } = useHomeSearchFilterContext()
  return (
    <button type='button' className='button-primary-out flex-1' onClick={clearSelect}>Clear</button>
  )
}
// 确定按钮
export function ConfirmButton({
  onConfirm
}) {

  const { totals, selectTotals, searchType, filter } = useHomeSearchFilterContext()
  const { confirmHandle, hasSuburbs } = useOnConfirm(onConfirm)

  const text = useMemo(() => {
    let res = (searchType && searchType.confirmText) || filter.confirmText
    const confirmTotalText = (searchType && searchType.confirmTotalText) || filter.confirmTotalText
    if (totals && confirmTotalText) {
      res = confirmTotalText.replace('{totals}', selectTotals || totals)
    }
    return res
  }, [selectTotals, totals, filter, searchType])
  return (
    <button type='button' className='button-orange flex-[2_2_0]' disabled={!hasSuburbs} onClick={confirmHandle}>{text}</button>
  )
}

function useOnConfirm(onConfirm) {
  const { filterQuery, routerData, description } = useHomeSearchFilterContext()
  const { suburbs, surrounding, isSearchSuburb } = useHomeSearchContext()

  const hasSuburbs = useMemo(() => size(suburbs) > 0 || !isSearchSuburb, [suburbs, isSearchSuburb])
  const confirmHandle = useCallback(() => {
    if (!hasSuburbs || !isFunction(onConfirm)) return
    onConfirm({
      suburbs,
      surrounding,
      description,
      filterQuery,
      ...routerData
    })
  }, [suburbs, surrounding, description, filterQuery, routerData, hasSuburbs, onConfirm])

  return {
    confirmHandle,
    hasSuburbs
  }
}