'use client'

import { LIVING_OPTIONS } from '@config'
import useHomeSearchFilterContext from '@homeSearch/modules/filter/context'
import IconAssistedLiving from '@icon/home/type-assisted-living.svg'
import IconIndependentLiving from '@icon/home/type-independent-living.svg'
import IconServicedApartments from '@icon/home/type-serviced-apartments.svg'
import IconSpecialistCareHomes from '@icon/home/type-specialist-care-homes.svg'
import { get, map, size } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { ItemBase } from '../index'
import classNames from 'classnames'

const ALL_LIVING_ICONS = {
  [LIVING_OPTIONS.independentLiving]: IconIndependentLiving,
  [LIVING_OPTIONS.servicedApartments]: IconServicedApartments,
  [LIVING_OPTIONS.assistedLiving]: IconAssistedLiving,
  [LIVING_OPTIONS.specialistCareHomes]: IconSpecialistCareHomes,
}

export default function LivingOptions({
  data
}) {
  const list = useMemo(() => {
    let res = get(data, 'children')
    if (size(res) === 0) {
      return []
    } else {
      res = [
        {
          alias: get(res, '0.alias'),
          value: -1,
          label: 'Any'
        },
        ...res
      ]
    }

    return res.map(item => {
      return { ...item, icon: ALL_LIVING_ICONS[item.value] || null }
    })
  }, [data])

  if (list.length === 0) {
    return null
  }

  return (
    <ItemBase data={data}>
      <ul className='flex space-x-8'>
        {
          map(list, (item, idx) => (
            <ItemBlock data={item} Icon={item.icon} key={idx} />
          ))
        }
      </ul>
    </ItemBase>
  )
}

function ItemBlock({ data, Icon }) {
  const { select, toggleSelectItem, clearSelectItem } = useHomeSearchFilterContext()
  const isActive = useMemo(() => {
    const val = get(select, data.alias, [])
    return val.includes(parseInt(data.value)) || (val.length === 0 && data.value === -1)
  }, [select, data])
  const clickHandle = useCallback(() => {
    if (data.value === -1) {
      clearSelectItem(data)
    } else {
      toggleSelectItem(data, false, true)
    }
  }, [data, clearSelectItem, toggleSelectItem])

  return (
    <li
      className={
        classNames(
          'flex-1 flex flex-col items-center justify-center px-14 cursor-pointer rounded-sm border min-h-[96px] min-w-[96px]',
          // data.num === 0 && !isActive ? 'cursor-not-allowed' : 'cursor-pointer',
          isActive ? 'bg-primary-light border-primary' : 'bg-neutral-1 border-transparent')
      }
      onClick={clickHandle}
    >
      {
        Icon && (<Icon width={38} height={38} fill={isActive ? 'var(--color-theme)' : undefined}></Icon>)
      }
      <span className={classNames('text-center text-sm leading-14', { 'mt-4': Boolean(Icon) }, isActive ? 'text-primary' : 'text-font')}>{data.label}</span>
    </li>
  )
}
