'use client'

import { DEFAULT_SPREAD_ALIAS, HOUSE_FILTER_ALIAS } from '@config'
import useHomeSearchFilterContext from '@homeSearch/modules/filter/context'
import classNames from 'classnames'
import { concat, get } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean, useWindowSize } from 'react-use'

export function ItemBase({
  data,
  children,
  collapse = true
}) {
  const { select, description } = useHomeSearchFilterContext()
  const [display, toggleDisplay] = useBoolean(
    DEFAULT_SPREAD_ALIAS.includes(data.alias)
    || data.alias === 'tua' && !!description
    || get(select, data.alias, []).length > 0
  )

  const boxRef = useRef(null)
  const [height, setHeight] = useState(0)
  const clickHandle = useCallback(() => collapse && toggleDisplay(), [toggleDisplay, collapse])

  const { width } = useWindowSize()
  useEffect(() => {
    if (!boxRef.current) return
    setHeight(boxRef.current.offsetHeight)
  }, [boxRef, setHeight, display, data, width])

  return (
    <div>
      <div className={classNames('flex justify-between', { 'cursor-pointer': collapse })} onClick={clickHandle}>
        <span className='text-font font-bold text-base'>{data.label}</span>
        {
          collapse && (
            <i className={classNames('icon icon-arrow-up font-bold transition-transform', { 'rotate-180': !display })}></i>
          )
        }
      </div>
      <div className={classNames('overflow-hidden transition-[height]', { 'mt-10': display })} style={{ height: display ? height : 0 }}>
        <div ref={boxRef}>
          {children}
        </div>
      </div>
    </div>
  )
}

export function ItemBlock({
  data,
  outNum = false
}) {

  const { select, toggleSelectItem } = useHomeSearchFilterContext()
  // modify
  const isActive = useMemo(() => get(select, data.alias, []).includes(parseInt(data.value)), [select, data])

  const clickHandle = useCallback(() => {
    toggleSelectItem(data)
  }, [data, toggleSelectItem])

  return (
    <div>
      <div className={classNames(
        'flex items-center h-48 border rounded-md px-16',
        { 'border-primary bg-primary-light': isActive },
        isActive || data.num ? 'cursor-pointer' : 'cursor-not-allowed',
        outNum ? 'justify-center text-base' : 'justify-between text-sm'
      )} onClick={clickHandle}>
        <span className={isActive ? 'text-primary' : 'text-neutral-5'} dangerouslySetInnerHTML={{ __html: (concat(HOUSE_FILTER_ALIAS.landarea, HOUSE_FILTER_ALIAS.floorarea).includes(data.alias) ? data.showLabel : data.label) || '' }}></span>
        {
          !outNum && (
            <span className={isActive ? 'text-primary' : 'text-neutral-3'}>({data.num})</span>
          )
        }
      </div>
      {
        outNum && (
          <div className='text-neutral-3 text-xs text-center mt-8'>({data.num})</div>
        )
      }
    </div>
  )
}
